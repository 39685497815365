import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ClientAction from "../../stores/client/ClientAction";
import RevenueClaimAction from "../../stores/revenueClaim/RevenueClaimAction";
import MuiButton from "../../components/muiButton/MuiButton";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import RevenueClaimRequest from "./RevenueClaimRequest";
import GenerateInvoice from "./GenerateInvoice";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import environment from "environment";
import BlockIcon from "@mui/icons-material/Block";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ClientAction.REQUEST_CLIENT_FILTER,
    RevenueClaimAction.REQUEST_REVENUE_CLAIM,
    RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE,
  ]),
  clientFilter: state.clientFilter.clientFilter || [],
  revenueClaim: state.revenueClaim.revenueClaim || [],
  revenueClaimFilter: state.revenueClaimFilter.revenueClaimFilter || [],
  pagination: state.revenueClaim.revenueClaim || [],
  clientRevenueBalance: state.clientRevenueBalance.clientRevenueBalance || [],
});

function RevenueClaim(props) {
  const { isRequesting } = props;
  const [page_limit] = useState(15);
  const [openClaimRequest, setOpenClaimRequest] = useState(false);
  const [CheckedClaim, setCheckedClaim] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [invoiceClaimDialogOpen, setInvoiceClaimDialogOpen] = useState(false);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [loading, setloading] = useState(false);
  const [invoiceRequestLoading, setInvoiceRequestLoading] = useState(false);

  function fetchRevenuClaim(params) {
    props.dispatch(RevenueClaimAction.requestRevenueClaim(params));
  }

  function fetchRevenuClaimFilter(params) {
    props.dispatch(RevenueClaimAction.requestRevenueClaimFilter(params));
  }

  function fetchClientRevenueBalance(clientId) {
    props.dispatch(RevenueClaimAction.requestClientRevenueBalance(clientId));
  }

  useEffect(() => {
    const params = {
      order_by: `[["created_on", "desc"]]`,
      page_limit: `${page_limit}`,
      current_page: "revenueClaim",
    };
    fetchRevenuClaim(params);
    const filter_params = {
      order_by: `[["created_on", "desc"]]`,
      page_limit: `none`,
      current_page: "revenueClaim",
    };
    const balance_params = {
      current_page: "revenueClaim",
    };
    fetchRevenuClaimFilter(filter_params);
    fetchClientRevenueBalance(balance_params);
  }, []);

  function formatStatusName(name) {
    const words = name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(" ");
  }
  const handlecheckbox = (e, params) => {
    setIsChecked(e.target.checked);
    let arr = [...CheckedClaim];

    if (e.target.checked) {
      arr.push({ claim_id: params.id });
    } else {
      arr = arr.filter((item) => item.claim_id !== params.id);
    }
    setCheckedClaim(arr);
  };
  const columns = [
    {
      field: "check",
      headerName: "",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 80,
      renderCell: (params) => {
        const index = params.value.index;
        const hasCheckbox = params.value.hasCheckbox;
        return (
          <>
            {hasCheckbox && (
              <Checkbox onClick={(e) => handlecheckbox(e, params)} />
            )}
          </>
        );
      },
    },
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "client_name",
      headerName: "Client",
      width: 150,
      flex: 2,
      hide: true,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 2,
      width: 100,
      renderCell: (params) => (
        <span>
          {params?.row?.type ? formatStatusName(params?.row?.type) : ""}
        </span>
      ),
    },
    // {
    //   field: "revised_date",
    //   headerName: "Revised Date",
    //   flex: 2,
    // },
    {
      field: "sales_period",
      headerName: "Sales Period",
      flex: 2,
      renderCell: (params) => (
        <span>
          {params.row.sales_period?.length &&
            params.row.sales_period.map((item, index) => (
              <span>
                {item}{" "}
                {index !== params.row.sales_period.length - 1 ? "," : null}
              </span>
            ))}
        </span>
      ),
    },

    {
      field: "claim_status",
      headerName: "Claim Status",
      flex: 2,
      renderCell: (params) => <span>{params?.row?.claim_status}</span>,
    },

    {
      field: "claim_value",
      headerName: "Royalties",
      flex: 2,
      renderCell: (params) => (
        <Typography
          component={"span"}
          sx={{
            color: params.row.type === "revenue" ? "green" : "red",
          }}
        >
          {params.row.type === "claim" ? "-" : null} {params?.row?.claim_value}
        </Typography>
      ),
    },

    {
      field: "edit",
      type: "actions",
      headerName: "Invoice Download",
      width: 160,
      hide: false,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => {
        return ["claim", "advance"].includes(params?.row?.type) ? (
          <IconButton
            title="Download"
            onClick={(event) => {
              event.ignore = true;
              setInvoiceData(params.row);
              setInvoiceDialogOpen(true);
            }}
            sx={{ border: "0" }}
          >
            <DownloadIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        ) : params?.row?.claim_status !== "not yet claimed" &&
          params?.row?.claim_status === "Requested" ? (
          <IconButton
            title="Requested"
            sx={{ border: "0" }}
            onClick={(event) => {
              event.ignore = true;
              setInvoiceData(params.row);
              setInvoiceClaimDialogOpen(true);
            }}
          >
            <FontAwesomeIcon
              style={{
                color: `${theme.palette.primary.main}`,
                height: "18px",
                width: "18px",
              }}
              icon={faDownload}
            ></FontAwesomeIcon>
          </IconButton>
        ) : (
          <IconButton
            sx={{ border: "0" }}
            onClick={(event) => {
              event.ignore = true;
            }}
          >
            <BlockIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        );
      },
    },
  ];

  const rows = [];
  if (props.revenueClaim && props.revenueClaim.hits) {
    props.revenueClaim.hits.hits &&
      props.revenueClaim.hits.hits.length > 0 &&
      props.revenueClaim.hits.hits.map((item, index) => {
        rows.push({
          check: {
            hasCheckbox: item._source?.claim_request_id === null,
          },
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",

          id: item._id,
          client_id: item._source.client_id?.id,
          client_name: item._source.client_id?.client_name,
          created_by: item._source.created_by.user_name,
          created_on: new Date(item._source.created_on).toLocaleString(),
          claim_request_id: item._source?.claim_request_id,
          claim_value: item?._source?.claim_value,
          late_claims: item?._source?.late_claims,
          revised_date: item?._source?.revised_date,
          sales_period: item?._source?.sales_period,
          claim_status: item._source?.claim_request_id
            ? item._source?.claim_request_id?.claim_status
              ? formatStatusName(item._source?.claim_request_id?.claim_status)
              : ""
            : "not yet claimed",
          type: item._source?.type,
          invoice: item._source?.claim_request_id?.invoice,
          invoice_number: item._source?.claim_request_id?.invoice_number,
          description: item?._source?.claim_request_id?.description,
        });
      });
  }

  const claimIds = [];

  if (props.revenueClaimFilter && props.revenueClaimFilter.hits) {
    props.revenueClaimFilter?.hits?.hits?.length &&
      props.revenueClaimFilter.hits.hits.map((item) => {
        !item?._source?.claim_request_id &&
          claimIds.push({
            claim_id: item._id,
          });
      });
  }

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];
    let params = {};

    params = {
      page_number: `${value}` - 1,
      order_by: `[["created_on", "desc"]]`,
      page_limit: `${page_limit}`,
      current_page: "revenueClaim",
    };

    if (value - 1 !== props.pagination.current_page_no) {
      fetchRevenuClaim(params);
    }
  };

  const handleClaim = () => {
    setOpenClaimRequest(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        justifyContent="space-between"
        pr={1}
        mb={1}
      >
        <Grid item sx={{ display: "flex" }} columnGap={2}>
          <Typography>
            Total Revenue :{" "}
            <Typography component={"span"} fontWeight={600} fontSize={18}>
              {props.clientRevenueBalance?.total_revenue ?? ""}
            </Typography>
          </Typography>

          <Typography>
            Claimed Revenue :{" "}
            <Typography component={"span"} fontWeight={600} fontSize={18}>
              {props.clientRevenueBalance?.total_claimed ?? ""}
            </Typography>
          </Typography>

          <Typography>
            Available Balance :{" "}
            <Typography component={"span"} fontWeight={600} fontSize={18}>
              {props.clientRevenueBalance?.balance ?? ""}
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          // mt={{ md: 1, lg: 0, sm: 1, xl: 0 }}
        >
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            container
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
          >
            <Grid
              item
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                color: theme.palette.secondary.main,
              }}
            >
              <marquee width="100%" direction="left" scrollamount="5">
                ** Payments claimed after the 5th will be processed and
                disbursed between the 1st and 15th of the following month. **
              </marquee>
            </Grid>
            {props.clientRevenueBalance?.total_revenue >= 100 && (
              <Grid item>
                <MuiButton
                  name={CheckedClaim?.length !== 0 ? "Claim" : "Claim All"}
                  width="100%"
                  onClick={handleClaim}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <GenerateInvoice
        open={invoiceDialogOpen}
        onClose={() => {
          setInvoiceDialogOpen(false);
        }}
        onSubmit={() => {
          setInvoiceData(null);
          setInvoiceDialogOpen(false);
        }}
        invoiceData={invoiceData}
      />
      <RevenueClaimRequest
        claimIds={claimIds}
        CheckedClaim={CheckedClaim}
        setCheckedClaim={setCheckedClaim}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        open={openClaimRequest}
        onClose={() => {
          setOpenClaimRequest(false);
        }}
        onSubmit={() => {
          const params = {
            order_by: `[["created_on", "desc"]]`,
            page_limit: `${page_limit}`,
            current_page: "revenueClaim",
          };
          fetchRevenuClaim(params);
          const filter_params = {
            order_by: `[["created_on", "desc"]]`,
            page_limit: `none`,
            current_page: "revenueClaim",
          };
          fetchRevenuClaimFilter(filter_params);
        }}
      />

      <Dialog
        open={invoiceClaimDialogOpen}
        onClose={() => setInvoiceClaimDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Revenue Claim"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Already Revenue request has been claimed for this month. You can
            request again or download invoice.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton
            name={"Send Invoice to Accounts"}
            loading={invoiceRequestLoading}
            onClick={() => {
              setInvoiceRequestLoading(true);
              props
                .dispatch(
                  RevenueClaimAction.requestGenerateClientInvoice(
                    {},
                    invoiceData?.claim_request_id?.id,
                    {}
                  )
                )
                .then(() => {
                  setInvoiceRequestLoading(false);
                  setInvoiceClaimDialogOpen(false);
                });
            }}
          />

          <MuiButton
            name={"Download Invoice"}
            loading={loading}
            onClick={() => {
              let access_token = localStorage.getItem("Auth")
                ? JSON.parse(localStorage.getItem("Auth")).access_token
                : "";

              setloading(true);

              fetch(
                `${environment.api.generateClientInvoice}/${invoiceData?.claim_request_id?.id}?download=true`,
                {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer " + access_token,
                  },
                }
              )
                .then((response) => {
                  // Check if the response status is OK (200)
                  if (!response.ok) {
                    response.json().then((data) => {
                      if (data.message) {
                        setErrorMessage(data.message);
                        setAlertPopUp(true);
                        setloading(false);
                      }
                    });
                    throw new Error("Network response was not ok");
                  }
                  // Check the content type in the response header
                  const contentType = response.headers.get("content-type");

                  // Extract the filename from the Content-Disposition header

                  if (contentType === "application/pdf") {
                    return response.blob(); // Convert the response to a Blob
                  }
                })
                .then((pdfBlob) => {
                  setloading(false);

                  // Create a Blob URL for the PDF data
                  const blobUrl = URL.createObjectURL(pdfBlob);

                  // Create an anchor element for downloading
                  const downloadLink = document.createElement("a");
                  downloadLink.href = blobUrl;
                  downloadLink.target = "_blank";
                  downloadLink.download = `${invoiceData.client_name}-${invoiceData.claim_request_id?.id}.pdf`; // Set the desired file name

                  // Trigger a click event on the anchor element to initiate the download
                  downloadLink.click();

                  // Clean up the Blob URL
                  URL.revokeObjectURL(blobUrl);
                })
                .catch((error) => {});
            }}
          />
        </DialogActions>
      </Dialog>

      {navigator.onLine ? (
        <Grid
          display="flex"
          justifyContent="center"
          container
          item
          lg={12}
          md={12}
          sm={12}
        >
          <Grid conatiner item lg={12} xl={12} md={12} sm={12} xs={12} pr={1}>
            <LoadingIndicator isActive={isRequesting}>
              {!isRequesting && props.revenueClaim.hits?.hits ? (
                <Box>
                  <DataGridFunction
                    rows={rows}
                    columns={columns}
                    height={"75vh"}
                    rowHeight={60}
                    pagelength={props.pagination.total_page_no}
                    page={props.pagination.current_page_no}
                    handleChange={handleChange}
                  />
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    }}
                  >
                    <marquee width="100%" direction="left" scrollamount="8">
                      <span>
                        <InfoIcon
                          style={{
                            fontSize: "24px",
                            margin: 0,
                            padding: 0,
                          }}
                        />
                      </span>{" "}
                      To Claim the Balance, Your available balance must be over
                      100$ Threshold
                    </marquee>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh",
                  }}
                >
                  <LoadingBgImage />
                </Box>
              )}
            </LoadingIndicator>
          </Grid>
          <MuiSnackbarAlert
            open={alertPopUp}
            onClose={() => setAlertPopUp(false)}
            message={errorMessage}
          />
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(RevenueClaim);
